import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';

export type BaseTileProps = {
  title: string;
  headerAction?: React.ReactNode;
  cardActions?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const BaseTile = (props: BaseTileProps) => {
  return (
    <Card
      style={{
        width: '300px',
        ...props.style,
      }}
    >
      <CardHeader title={props.title} action={props.headerAction ?? null} />
      <CardContent>{props.children}</CardContent>
      {props.cardActions && <CardActions>{props.cardActions}</CardActions>}
    </Card>
  );
};
