import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { graphql } from '@octokit/graphql';
import type { GraphQlQueryResponseData } from '@octokit/graphql';
import { useSupportedGitHubOrgs, useGithubToken } from '../utils';

/**
 * Populate the orgs array with the GitHub orgs the user is a member of, using the provided token.
 * @param token The token to use to query the GitHub API.
 */
function getOrgs(token: string, supportedOrgs: string[]): string[] {
  const orgs: string[] = [];
  const query = `
        query {
            viewer {
                organizations(first: 100) {
                    nodes {
                        login
                    }
                }
            }
        }
    `;

  graphql<GraphQlQueryResponseData>(query, {
    headers: {
      authorization: `token ${token}`,
    },
  }).then(response => {
    for (const node of response.viewer.organizations.nodes) {
      if (supportedOrgs.includes(node.login)) {
        orgs.push(node.login);
      }
    }
  });
  return orgs;
}

export const GithubOrgPicker = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    schema: {
      title = 'Github Organization',
      description = 'Github Organization',
    },
    required,
    rawErrors = [],
    formData = {},
  } = props;

  const { setSecrets } = useTemplateSecrets();

  const [availableOrgs, setAvailableOrgs] = useState<string[]>(['rvohealth']);

  const supportedOrgs = useSupportedGitHubOrgs();
  const token = useGithubToken();

  useEffect(() => {
    const loadOrgInputs = async () => {
      if (!token) return;
      // set the secret for use in the templating the manifest
      setSecrets({ ['USER_OAUTH_TOKEN']: token });
      setAvailableOrgs(getOrgs(token, supportedOrgs));
    };

    loadOrgInputs();
  }, [token, supportedOrgs, setSecrets]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        value={(formData as string) || ''}
        loading={false}
        onChange={(_, value) => onChange(value ?? '')}
        options={availableOrgs}
        autoSelect
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
            placeholder="Organizations"
          />
        )}
      />
    </FormControl>
  );
};

export const GithubOrgPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GithubOrgPicker,
    name: 'GithubOrgPicker',
  }),
);
