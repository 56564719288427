import {
  CatalogApi,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import type { CompoundEntityRef, Entity } from '@backstage/catalog-model';
import {
  RELATION_PARENT_OF,
  stringifyEntityRef,
  getCompoundEntityRef,
} from '@backstage/catalog-model';

// Gets immediate child groups of a given group
async function getChildGroups(
  parentGroupRef: string,
  catalogApi: CatalogApi,
  token: string,
): Promise<CompoundEntityRef[]> {
  // get the group
  const entity = await catalogApi.getEntityByRef(parentGroupRef, {
    token: token,
  });

  return getEntityRelations(entity, RELATION_PARENT_OF, {
    kind: 'Group',
  });
}

// takes in a parent group entity and returns an array of entity refs (strings) for all child groups of the parent group inclusive of the parent group
// inspired by: https://github.com/backstage/backstage/blob/master/plugins/org/src/components/Cards/OwnershipCard/useGetEntities.ts
export const getAllChildGroupsWithParent = async (
  parentGroup: Entity,
  catalogApi: CatalogApi,
  token: string,
): Promise<string[]> => {
  // holds any remaining groups to be processed
  const remainingGroups = [];
  // holds any children group found in the hierarchy chain
  const childrenGroups = [];

  let pointer: CompoundEntityRef | undefined =
    getCompoundEntityRef(parentGroup);

  // loop through remainingGroups array until it is empty
  // we add any new found children to the remainingGroups array and remove the current group from the array once finished
  while (pointer) {
    // get any child groups of the current group
    const children = await getChildGroups(
      stringifyEntityRef(pointer),
      catalogApi,
      token,
    );

    // add any found children to be further processed
    remainingGroups.push(...children);

    // save any found groups
    childrenGroups.push(pointer);

    pointer = remainingGroups.shift();
  }
  // return an array of entity refs (strings) for all child groups inclusive of the parent group
  return childrenGroups.map(group => stringifyEntityRef(group));
};
