import {
  createApiRef,
  IdentityApi,
  ConfigApi,
} from '@backstage/core-plugin-api';
import {
  GitHubIssuesResponse,
  SecurityMetricsAPI,
  WizIssuesResponse,
} from '@internal/security-metrics-common';

export const securityMetricsApiRef = createApiRef<SecurityMetricsAPI>({
  id: 'plugin.security-metrics',
});

export class RequestError extends Error {
  resp: Response;
  status: number;

  constructor(resp: Response) {
    super(`securityMetrics request failed with a ${resp.status} status code`);
    this.resp = resp;
    this.status = resp.status;

    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export class SecurityMetricsClient implements SecurityMetricsAPI {
  private readonly identityApi: IdentityApi;
  private readonly baseURL: string;

  constructor(options: { identityApi: IdentityApi; configApi: ConfigApi }) {
    this.identityApi = options.identityApi;
    this.baseURL = options.configApi.getString('backend.baseUrl');
  }

  // the group name, not the entity ref: assumes default namespace
  async getWizIssuesForGroup(group: string): Promise<WizIssuesResponse | null> {
    try {
      const resp = await this.do<undefined, WizIssuesResponse>(
        'wiz/issues',
        'GET',
        undefined,
        { group },
      );
      return resp;
    } catch (err) {
      if (err instanceof RequestError && err.status === 404) {
        return {} as WizIssuesResponse;
      }

      throw err;
    }
  }

  async getWizIssuesByResourceIDs(
    resourceIDs: string[],
  ): Promise<WizIssuesResponse | null> {
    try {
      const resp = await this.do<undefined, WizIssuesResponse>(
        'wiz/issues',
        'GET',
        undefined,
        { resourceIDs: resourceIDs.join(',') },
      );
      return resp;
    } catch (err) {
      if (err instanceof RequestError && err.status === 404) {
        return {} as WizIssuesResponse;
      }

      throw err;
    }
  }

  async getGitHubIssuesForGroup(group: string): Promise<GitHubIssuesResponse> {
    const resp = await this.do<undefined, GitHubIssuesResponse>(
      'github/issues',
      'GET',
      undefined,
      { group },
    );
    return resp!;
  }

  private async do<T, R>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: T,
    params?: Record<string, string>,
  ): Promise<R | null> {
    const url = new URL(`/api/security-metrics/${path}`, this.baseURL);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    const { token } = await this.identityApi.getCredentials();
    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    const resp = await fetch(url.toString(), {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      throw new RequestError(resp);
    }

    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }

    return null;
  }
}
