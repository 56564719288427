import {
  CompoundEntityRef,
  DEFAULT_NAMESPACE,
  Entity,
  GroupEntity,
  RELATION_PARENT_OF,
  stringifyEntityRef,
  UserEntity,
} from '@backstage/catalog-model';
import {
  CatalogApi,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';

export const getMembersFromGroups = async (
  groups: CompoundEntityRef[],
  catalogApi: CatalogApi,
) => {
  if (groups.length === 0) return [];

  const membersList = await catalogApi.getEntities({
    filter: {
      kind: 'User',
      'relations.memberof': groups.map(group =>
        stringifyEntityRef({
          kind: 'group',
          namespace: group.namespace.toLocaleLowerCase('en-US'),
          name: group.name.toLocaleLowerCase('en-US'),
        }),
      ),
    },
  });

  return membersList.items as UserEntity[];
};

export const getDescendantGroupsFromGroup = async (
  group: GroupEntity,
  catalogApi: CatalogApi,
) => {
  const alreadyQueuedOrExpandedGroupNames = new Set<string>();
  const groupRef: CompoundEntityRef = {
    kind: group.kind,
    namespace: group.metadata.namespace ?? DEFAULT_NAMESPACE,
    name: group.metadata.name,
  };

  const groupQueue: CompoundEntityRef[] = [];
  const resultantGroupRefs: CompoundEntityRef[] = [groupRef];

  let pointer: CompoundEntityRef | undefined = groupRef;

  // Continue expanding groups until there are no more
  while (pointer) {
    const activeGroup = await catalogApi.getEntityByRef(pointer);
    alreadyQueuedOrExpandedGroupNames.add(stringifyEntityRef(pointer));

    const childGroups = getEntityRelations(activeGroup, RELATION_PARENT_OF, {
      kind: 'Group',
    }).filter(
      currentGroup =>
        !alreadyQueuedOrExpandedGroupNames.has(
          stringifyEntityRef(currentGroup),
        ),
    );
    childGroups.forEach(childGroup =>
      alreadyQueuedOrExpandedGroupNames.add(stringifyEntityRef(childGroup)),
    );

    groupQueue.push(...childGroups);
    resultantGroupRefs.push(...childGroups);
    pointer = groupQueue.shift();
  }

  return resultantGroupRefs;
};

export const getAllDesendantMembersForGroupEntity = async (
  groupEntity: GroupEntity,
  catalogApi: CatalogApi,
) =>
  getMembersFromGroups(
    await getDescendantGroupsFromGroup(groupEntity, catalogApi),
    catalogApi,
  );

export const removeDuplicateEntitiesFrom = (entityArray: Entity[]) => {
  const seenEntities = new Set<string>();

  return entityArray.filter(entity => {
    const stringifiedEntity = stringifyEntityRef(entity);
    const isDuplicate = seenEntities.has(stringifiedEntity);
    seenEntities.add(stringifiedEntity);
    return !isDuplicate;
  });
};
