import {
  ExploreLayout,
  ToolExplorerContent,
  GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import React from 'react';
import { Direction } from '@backstage/plugin-catalog-graph';

export const ExplorePage = () => {
  return (
    <ExploreLayout title="Explore the tech organization">
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent direction={Direction.BOTTOM_TOP} />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
