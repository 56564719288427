import { Typography } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import React from 'react';

export type BaseSectionProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};

export const BaseSection: React.FC<BaseSectionProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Stack spacing="12px" width="100%" padding="24px">
      <Typography variant="h4">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      <Stack
        direction="row"
        style={{
          width: '100%',
          marginTop: '12px',
        }}
        spacing="6px"
        justifyContent="space-around"
      >
        {children}
      </Stack>
    </Stack>
  );
};
