import {
  createApiRef,
  IdentityApi,
  ConfigApi,
} from '@backstage/core-plugin-api';
import {
  PutNotificationRequest,
  DeleteNotificationRequest,
  GetNotficationsResponse,
  GetNotificationsFilterOptions,
  GetInstalledSlackChannelsResponse,
} from '@internal/teams-common';

export const teamsApiRef = createApiRef<teamsApi>({
  id: 'plugin.teams',
});

export class RequestError extends Error {
  resp: Response;
  status: number;

  constructor(resp: Response) {
    super(`teams request failed with a ${resp.status} status code`);
    this.resp = resp;
    this.status = resp.status;

    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export interface teamsApi {
  putNotification(input: PutNotificationRequest): Promise<void>;
  deleteNotification(input: DeleteNotificationRequest): Promise<void>;
  getNotifications(
    filters?: GetNotificationsFilterOptions,
  ): Promise<GetNotficationsResponse>;
  getInstalledSlackChannels(): Promise<GetInstalledSlackChannelsResponse>;
}

export class TeamsClient implements teamsApi {
  private readonly identityApi: IdentityApi;
  private readonly baseURL: string;

  constructor(options: { identityApi: IdentityApi; configApi: ConfigApi }) {
    this.identityApi = options.identityApi;
    this.baseURL = options.configApi.getString('backend.baseUrl');
  }

  async putNotification(input: PutNotificationRequest): Promise<void> {
    await this.do('notification', 'PUT', input);
  }

  async deleteNotification(input: DeleteNotificationRequest): Promise<void> {
    await this.do('notification', 'DELETE', input);
  }

  async getNotifications(
    filters?: GetNotificationsFilterOptions,
  ): Promise<GetNotficationsResponse> {
    const queryParams: Record<string, string> = {};

    if (filters?.team) {
      queryParams.team = filters.team;
    }

    if (filters?.type) {
      queryParams.type = filters.type;
    }

    const resp = await this.do<undefined, GetNotficationsResponse>(
      'notifications',
      'GET',
      undefined,
      queryParams,
    );

    if (!resp) {
      return { notifications: [] };
    }

    return resp;
  }

  async getInstalledSlackChannels(): Promise<GetInstalledSlackChannelsResponse> {
    return (await this.do('slack/channels', 'GET')) || { channels: [] };
  }

  private async do<T, R>(
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: T,
    params?: Record<string, string>,
  ): Promise<R | null> {
    const url = new URL(`/api/teams/${path}`, this.baseURL);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    const { token } = await this.identityApi.getCredentials();
    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    const resp = await fetch(url.toString(), {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!resp.ok) {
      throw new RequestError(resp);
    }

    if (resp.status === 200 || resp.status === 201) {
      return await resp.json();
    }

    return null;
  }
}
