import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { getGroupMembership } from '../util/getGroupMembership';
import { useUserProfile } from './useUserProfile';
import { cloudAccountsApiRef } from '@internal/cloud-accounts';

export const useUserCloudCosts = () => {
  const profile = useUserProfile();
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const cloudAccountsApi = useApi(cloudAccountsApiRef);

  const { value, loading, error } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();

    if (!token || !profile?.backstageIdentity?.userEntityRef) {
      return [];
    }

    const groups = await getGroupMembership(
      catalogApi,
      profile.backstageIdentity.userEntityRef,
      token,
    );

    const cloudAccountCosts = await cloudAccountsApi.getCloudAccountsCosts();

    const cloudAccountsForGroup = await catalogApi.getEntities(
      {
        filter: [
          {
            kind: ['Resource'],
            'spec.type': 'aws-account',
            'relations.ownedBy': groups,
          },
          {
            kind: ['Resource'],
            'spec.type': 'azure-subscription',
            'relations.ownedBy': groups,
          },
          {
            kind: ['Resource'],
            'spec.type': 'gcp-project',
            'relations.ownedBy': groups,
          },
        ],
      },
      {
        token,
      },
    );

    // filter costs down just to those that the user or their team owns
    return cloudAccountCosts.filter(cost =>
      cloudAccountsForGroup.items.some(
        account => account.metadata.name === cost.accountID,
      ),
    );
  }, [profile?.backstageIdentity?.userEntityRef]);

  return { value, loading, error };
};
