import {
  alertApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

export const useUserProfile = () => {
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);

  const { value, loading, error } = useAsync(async () => {
    try {
      const [profile, identity] = await Promise.all([
        identityApi.getProfileInfo(),
        identityApi.getBackstageIdentity(),
      ]);
      return { profile, identity };
    } catch (e) {
      alertApi.post({
        message: `Failed to load user identity: ${e}`,
        severity: 'error',
      });
      throw e;
    }
  }, []);

  if (loading || error || !value) {
    return null;
  }

  return {
    profile: value.profile,
    backstageIdentity: value.identity,
    displayName: value.profile.displayName ?? value.identity.userEntityRef,
    loading,
  };
};
