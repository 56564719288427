import React from 'react';

export const RVOHHorizontaWhitelLogo = (props: {
  classes: {
    svg: string;
    path: string;
  };
}) => {
  return (
    <svg
      className={props.classes.svg}
      width="439"
      height="68"
      viewBox="0 0 439 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1175_14593)">
        <path
          className={props.classes.path}
          d="M18.5088 41.6475L37.6321 66.7923H40.7027L21.5453 41.6475H18.5088Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M45.6332 36.9858C48.5674 35.3866 50.9215 33.141 52.6445 30.2828C54.3846 27.4247 55.2546 24.1072 55.2546 20.2963C55.2546 16.4855 54.3846 13.168 52.6445 10.3098C50.9215 7.45169 48.5674 5.24003 45.6332 3.62382C42.682 2.02462 39.4066 1.20801 35.8242 1.20801H0V66.7922H2.49064V3.67486H35.8242C38.9631 3.67486 41.812 4.35537 44.3879 5.71639C46.9468 7.0604 48.9939 8.99985 50.4951 11.5007C52.0304 14.0016 52.781 16.9278 52.781 20.2963C52.781 23.6649 52.0133 26.591 50.4951 29.1089C48.9939 31.6098 46.9297 33.5323 44.3879 34.8933C41.812 36.2373 38.9631 36.9348 35.8242 36.9348H16.3768V39.3676H35.8242C39.4066 39.3676 42.682 38.568 45.6332 36.9688V36.9858Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M150.564 2.38179C168.254 2.38179 182.652 16.5704 182.652 34.0085C182.652 51.4466 168.254 65.6352 150.564 65.6352C132.874 65.6352 118.476 51.4466 118.476 34.0085C118.476 16.5704 132.874 2.38179 150.564 2.38179ZM150.564 0C131.509 0 116.07 15.2264 116.07 34.0085C116.07 52.7906 131.509 68.017 150.564 68.017C169.619 68.017 185.057 52.7906 185.057 34.0085C185.057 15.2264 169.619 0 150.564 0Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M59.9289 1.20801H57.3018L78.2333 62.7772L79.5639 59.0344L59.9289 1.20801Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M102.457 1.20801L80.041 66.7922H82.6852L105.101 1.20801H102.457Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M25.2139 41.6475L44.3713 66.7923H47.4419L28.438 41.6475H25.2139Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M43.9273 12.0281C46.162 14.0016 47.2879 16.7577 47.2879 20.2963C47.2879 23.835 46.162 26.5911 43.9273 28.5816C41.6925 30.555 38.9801 31.5588 35.7901 31.5588H16.3427V33.9916H35.7901C38.3831 33.9916 40.7202 33.4302 42.8184 32.3244C44.9167 31.2185 46.6056 29.6363 47.868 27.5948C49.1303 25.5703 49.7615 23.1375 49.7615 20.2963C49.7615 17.4552 49.1474 15.0394 47.868 12.9979C46.6056 10.9563 44.9167 9.39117 42.8184 8.26832C40.7031 7.1625 38.3831 6.60107 35.7901 6.60107H5.4248V66.7922H7.89838V9.0339H35.773C38.9631 9.0339 41.6755 10.0206 43.9102 12.0281H43.9273Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M150.513 7.75776C165.201 7.75776 177.142 19.5306 177.142 33.9914C177.142 48.4523 165.201 60.2251 150.513 60.2251C135.825 60.2251 123.884 48.4523 123.884 33.9914C123.884 19.5306 135.825 7.75776 150.513 7.75776ZM150.513 5.37598C134.478 5.37598 121.479 18.1866 121.479 33.9914C121.479 49.7963 134.478 62.6069 150.513 62.6069C166.549 62.6069 179.548 49.7963 179.548 33.9914C179.548 18.1866 166.549 5.37598 150.513 5.37598Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M65.6779 1.20801H63.0508L81.0823 54.4579L82.4129 50.477L65.6779 1.20801Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M108.206 1.20801L85.79 66.7922H88.4342L110.85 1.20801H108.206Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M32.3438 41.6475L51.16 66.7923H54.2306L35.5509 41.6475H32.3438Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M40.0373 15.8219C41.2315 16.7576 41.8115 18.2377 41.8115 20.2963C41.8115 22.3548 41.2315 23.8009 40.0373 24.7366C38.8432 25.6893 37.4273 26.1657 35.7726 26.1657H16.3422V28.6155H35.7896C38.1608 28.6155 40.1738 27.901 41.8286 26.4889C43.5004 25.0769 44.3192 23.0013 44.3192 20.2793C44.3192 17.5572 43.5004 15.4987 41.8286 14.0696C40.1738 12.6576 38.1779 11.9771 35.7896 11.9771H10.8662V66.7922H13.3568V14.4269H35.7726C37.4273 14.4269 38.8432 14.8862 40.0373 15.8219Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M150.513 13.1337C162.182 13.1337 171.684 22.4908 171.684 33.9914C171.684 45.492 162.182 54.849 150.513 54.849C138.845 54.849 129.343 45.492 129.343 33.9914C129.343 22.4908 138.845 13.1337 150.513 13.1337ZM150.513 10.752C137.497 10.752 126.938 21.1638 126.938 33.9914C126.938 46.819 137.497 57.2308 150.513 57.2308C163.529 57.2308 174.089 46.819 174.089 33.9914C174.089 21.1638 163.529 10.752 150.513 10.752Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M71.4777 1.20801H68.8506L83.982 45.9005L85.3127 42.0727L71.4777 1.20801ZM114.006 1.20801L91.5904 66.7752H94.2517L116.65 1.20801H113.989H114.006Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M241.643 31.5759H200.769V2.96045H197.187V66.418H200.769V34.8424H241.643V66.418H245.225V2.96045H241.643V31.5759Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M273.731 20.1436C260.562 20.1436 250.258 30.5043 250.258 43.7232C250.258 56.9422 260.783 67.1328 273.731 67.1328C282.261 67.1328 290.364 62.9136 294.867 56.1255L295.311 55.462H290.859L290.739 55.6322C286.798 60.719 280.299 63.8664 273.748 63.8664C262.728 63.8664 254.079 55.36 253.857 44.4208H296.949V43.1788C296.573 29.8238 286.816 20.1436 273.748 20.1436H273.731ZM293.11 41.0692H253.891C255.393 30.9637 263.837 23.41 273.731 23.41C283.626 23.41 291.626 30.6404 293.11 41.0692Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M325.352 20.1433C319.176 19.9902 313.001 22.5591 308.412 27.1526C303.908 31.661 301.383 37.6665 301.52 43.6379C301.383 49.6264 303.908 55.6319 308.412 60.1403C312.898 64.6147 318.886 67.1496 324.891 67.1496H325.352C333.489 67.1496 341.234 62.6582 345.515 55.5639V66.418H349.098V43.6379C349.234 37.6154 346.71 31.5929 342.206 27.0845C337.651 22.5251 331.493 19.9902 325.352 20.1263V20.1433ZM345.618 43.6379C345.618 54.7983 336.576 63.8661 325.454 63.8661C314.331 63.8661 305.102 54.7983 305.102 43.6379C305.102 32.4776 314.229 23.4098 325.454 23.4098C336.679 23.4098 345.618 32.4776 345.618 43.6379Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M359.078 2.96045H355.495V66.401H359.078V2.96045Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M418.615 20.1433C410.648 20.1433 405.394 22.8484 401.794 28.8539V2.96045H398.212V66.418H401.794V41.8516C401.794 30.3 408.072 23.4098 418.615 23.4098C429.157 23.4098 435.418 30.5381 435.418 42.4811V66.418H439V42.4811C439 28.2924 431.563 20.1433 418.615 20.1433Z"
          fill="#ffffff"
        />
        <path
          className={props.classes.path}
          d="M391.132 62.25C390.433 62.5222 389.784 62.6754 389.273 62.7944C388.147 62.9986 386.953 63.1177 385.724 63.1177C383.131 63.1177 380.828 62.9646 378.952 61.0932C377.229 59.3749 377.229 57.8437 377.246 55.0707V24.4307H391.251V21.1302H377.246V8.5918H373.408V21.1302H364.912V24.4307H373.408V55.6661C373.408 59.562 374.397 62.233 376.444 63.8663C378.542 65.5845 381.221 66.4182 384.598 66.4182C386.373 66.4182 388.061 66.231 389.614 65.8738C390.603 65.6186 391.405 65.3804 392.104 65.1252L392.48 64.9721L391.541 62.0969L391.115 62.25H391.132Z"
          fill="#ffffff"
        />
      </g>
      <defs>
        <clipPath id="clip0_1175_14593">
          <rect width="439" height="68" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
