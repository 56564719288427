import { RELATION_MEMBER_OF } from '@backstage/catalog-model';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { getAllChildGroupsWithParent } from '@internal/plugin-common-react';

export async function getGroupMembership(
  catalogApi: CatalogApi,
  ref: string,
  token: string,
) {
  const user = await catalogApi.getEntityByRef(ref);

  if (!user) {
    return [];
  }

  const memberGroups: string[] = [];
  for (const x of user?.relations || []) {
    if (x.type === RELATION_MEMBER_OF) {
      memberGroups.push(x.targetRef);
    }
  }

  // lookup all child groups of the member groups and combine them into a single array
  const groups = (
    await Promise.all(
      memberGroups.map(async mg => {
        const parentGroup = await catalogApi.getEntityByRef(mg);
        if (!parentGroup) {
          return [];
        }

        return await getAllChildGroupsWithParent(
          parentGroup,
          catalogApi,
          token,
        );
      }),
    )
  ).flat();

  // dedupe the array and return it
  return [...new Set(groups)];
}
