import { Entity } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { IconButton } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import React from 'react';
import { BaseTile } from './BaseTile';

export type ResourceTileProps = {
  type: string;
  resources: Entity[];
  owningGroups: string[];
};

const singleResourceLabels: Record<string, string> = {
  'aws-account': 'AWS Account',
  'azure-subscription': 'Azure Subscription',
  'gcp-project': 'GCP Project',
  'kubernetes-cluster': 'Kubernetes Cluster',
  'kubernetes-service': 'Kubernetes Service',
  openapi: 'OpenAPI API',
  website: 'Website',
  library: 'Library',
  documentation: 'Doc Site',
  service: 'Service',
  'iac-pipeline': 'IaC Pipeline',
  'event-driven-service': 'Event Driven Service',
  asyncapi: 'AsyncAPI API',
  graphql: 'GraphQL API',
  grpc: 'gRPC API',
  group: 'Group',
  'terraform-module': 'Terraform Module',
  'terraform-repo': 'Terraform Repo',
  url: 'URL',
  cdk: 'CDK Repo',
  'argo-cd': 'Argo CD Repo',
};

const multipleResourcesLabels: Record<string, string> = {
  'aws-account': 'AWS Accounts',
  'azure-subscription': 'Azure Subscriptions',
  'gcp-project': 'GCP Projects',
  'kubernetes-cluster': 'Kubernetes Clusters',
  'kubernetes-service': 'Kubernetes Services',
  openapi: 'OpenAPI APIs',
  website: 'Websites',
  library: 'Libraries',
  documentation: 'Doc Sites',
  service: 'Services',
  'iac-pipeline': 'IaC Pipelines',
  'event-driven-service': 'Event Driven Services',
  asyncapi: 'AsyncAPI APIs',
  graphql: 'GraphQL APIs',
  grpc: 'gRPC APIs',
  group: 'Groups',
  'terraform-module': 'Terraform Modules',
  'terraform-repo': 'Terraform Repos',
  url: 'URLs',
  cdk: 'CDK Repos',
  'argo-cd': 'Argo CD Repos',
};

const baseTileStyles: React.CSSProperties = {
  height: '200px',
  width: '200px',
};

const resourceStyles: Record<string, React.CSSProperties> = {
  'aws-account': {
    background: 'linear-gradient(to right bottom, #430089, #82ffa1)',
  },
  'azure-subscription': {
    background: 'linear-gradient(to right bottom, #ff6a00, #ff00ff)',
  },
  'gcp-project': {
    background: 'linear-gradient(to right bottom, #1611DD, #FBD786)',
  },
  'kubernetes-cluster': {
    background: 'linear-gradient(to right bottom, #12c2e9, #c471ed)',
  },
  'kubernetes-service': {
    background: 'linear-gradient(to right bottom, #c471ed, #f64f59)',
  },
  openapi: {
    background: 'linear-gradient(to right bottom, #1f4037, #99f2c8)',
  },
  website: {
    background: 'linear-gradient(to right bottom, #373B44, #4286f4)',
  },
  library: {
    background: 'linear-gradient(to right bottom, #1980B9, #94D5FA)',
  },
  documentation: {
    background: 'linear-gradient(to right bottom, #8E2DE2, #4A00E0)',
  },
  service: {
    background: 'linear-gradient(to right bottom, #c31432, #240b36)',
  },
  'iac-pipeline': {
    background: 'linear-gradient(to right bottom, #6be585, #dd3e54)',
  },
  'event-driven-service': {
    background: 'linear-gradient(to right bottom, #654ea3, #eaafc8)',
  },
  asyncapi: {
    background: 'linear-gradient(to right bottom, #5D26C1, #a17fe0)',
  },
  graphql: {
    background: 'linear-gradient(to right bottom, #3c1053, #ad5389)',
  },
  grpc: {
    background: 'linear-gradient(to right bottom, #C06C84, #355C7D)',
  },
  group: {
    background: 'linear-gradient(to right bottom, #f12711, #f5af19)',
  },
  'terraform-module': {
    background: 'linear-gradient(to right bottom, #000428, #004e92)',
  },
  'terraform-repo': {
    background: 'linear-gradient(to right bottom, #000000, #434343)',
  },
  url: {
    background: 'linear-gradient(to right bottom, #1e3c72, #2a5298)',
  },
  cdk: {
    background: 'linear-gradient(to right bottom, #FF512F, #DD2476)',
  },
  'argo-cd': {
    background: 'linear-gradient(to right bottom, #441E30, #243B55)',
  },
};

const getBackstageKind = (type: string) => {
  switch (type) {
    case 'aws-account':
    case 'azure-subscription':
    case 'gcp-project':
      return 'resource';
    case 'group':
      return 'group';
    case 'openapi':
    case 'asyncapi':
    case 'graphql':
      return 'api';
    case 'url':
      return 'location';
    default:
      return 'component';
  }
};

const getBackstageType = (type: string | undefined) => {
  switch (type) {
    case 'group':
      return undefined;
    default:
      return type;
  }
};

export const ResourceTile: React.FC<ResourceTileProps> = ({
  type,
  resources,
  owningGroups,
}) => {
  const style = {
    ...baseTileStyles,
    ...(resourceStyles[type] ?? {}),
  };

  const ownerUrlPart = owningGroups
    .map(group => `filters%5Bowners%5D=${encodeURIComponent(group)}`)
    .join('&');

  const kindFilter = `filters%5Bkind%5D=${getBackstageKind(type)}&`;

  const backstageType = getBackstageType(type);
  const typeFilter = backstageType
    ? `filters%5Btype%5D=${getBackstageType(type)}&`
    : '';

  const drillDownLink = `/catalog/?${kindFilter}${typeFilter}${ownerUrlPart}&filters%5Buser%5D=all`;

  return (
    <BaseTile
      style={style}
      title={`${resources.length} ${
        resources.length > 1
          ? multipleResourcesLabels[type] ?? type
          : singleResourceLabels[type] ?? type
      }`}
      cardActions={
        <IconButton
          aria-label={`deep-dive-${type}`}
          component={Link}
          to={drillDownLink}
        >
          <ArrowForward />
        </IconButton>
      }
    />
  );
};
