import { useEffect, useRef } from 'react';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { useGithubToken } from '../utils';
import { Octokit } from '@octokit/rest';

type Config = {
  accountId: string;
  environment?: string;
  configDirectory: string;
  rootModules: string[];
}[];

export const InfraConfigRead = (
  props: FieldExtensionComponentProps<Config>,
) => {
  const { onChange, formContext } = props;

  const token = useGithubToken();

  const onChangeRef = useRef(onChange);

  // used to set available selectable options for the field
  useEffect(() => {
    (async () => {
      if (!formContext.formData.infraRepo || !token) {
        return;
      }
      const octokit = new Octokit({ auth: token });
      // parse the repo and owner from a string like: "repo=tf-developer-platforms&owner=rvohealth" at formContext.formData.infraRepo
      const repo = formContext.formData.infraRepo.split('&')[0].split('=')[1];
      const org = formContext.formData.infraRepo.split('&')[1].split('=')[1];

      // get contents of config.json from repo
      const result = await octokit.repos.getContent({
        repo,
        owner: org,
        path: 'config.json',
      });

      // decode result.data.content

      if (!('content' in result.data)) {
        return;
      }

      const config = JSON.parse(atob(result.data.content)) as Config;

      onChangeRef.current(config);
    })();
  }, [formContext.formData.infraRepo, token, onChangeRef]);

  return null;
};

export const InfraConfigReadFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: InfraConfigRead,
    name: 'InfraConfigRead',
    // this allows us to test at the create/edit page
    schema: {
      returnValue: { type: 'string' },
    },
  }),
);
