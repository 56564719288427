import {
  ANNOTATION_EDIT_URL,
  RELATION_MEMBER_OF,
  UserEntity,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import GitHubIcon from '@material-ui/icons/GitHub';
import Work from '@material-ui/icons/Work';
import ManageAccountsIcon from '@material-ui/icons/PersonAdd';
import { LinksGroup } from '../../shared';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';

const CardTitle = (props: { title?: string }) =>
  props.title ? (
    <Box display="flex" alignItems="center">
      <PersonIcon fontSize="inherit" />
      <Box ml={1}>{props.title}</Box>
    </Box>
  ) : null;

export const UserProfileCard = (props: {
  variant?: InfoCardVariants;
  showLinks?: boolean;
}) => {
  const { entity: user } = useEntity<UserEntity>();
  const {
    metadata: { name: metaName, description, links, annotations },
    spec: { profile },
  } = user;

  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const entityMetadataEditUrl =
    user.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const githubUsername = annotations?.['github.com/username'];
  const tile = annotations?.['rvohealth.com/job-title'];
  const manager = annotations?.['rvohealth.com/job-manager'];

  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : undefined;
  const managerHref = manager ? `mailto:${manager}` : undefined;
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });

  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {entityMetadataEditUrl && (
            <IconButton
              aria-label="Edit"
              title="Edit Metadata"
              component={Link}
              to={entityMetadataEditUrl}
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      }
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>

        <Grid item md={10} xl={11}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref ?? ''}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            {githubUsername && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="GitHub Username">
                    <GitHubIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={`https://github.com/${githubUsername}`}>
                    {githubUsername}
                  </Link>
                </ListItemText>
              </ListItem>
            )}

            {tile && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Title">
                    <Work />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to="">{tile}</Link>
                </ListItemText>
              </ListItem>
            )}

            {manager && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Manager">
                    <ManageAccountsIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={managerHref ?? ''}>{manager}</Link>
                </ListItemText>
              </ListItem>
            )}

            <ListItem>
              <ListItemIcon>
                <Tooltip title="Member of">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                <EntityRefLinks
                  entityRefs={memberOfRelations}
                  defaultKind="Group"
                />
              </ListItemText>
            </ListItem>

            {props?.showLinks && <LinksGroup links={links} />}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
