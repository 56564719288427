import { useState, useEffect } from 'react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';

export const useSupportedGitHubOrgs = () => {
  const config = useApi(configApiRef);
  return config.getStringArray('rvohealth.supportedGitHubOrgs');
};

export const useGithubToken = () => {
  const scmAuthApi = useApi(scmAuthApiRef);

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const creds = await scmAuthApi.getCredentials({
        url: `https://github.com`,
        additionalScope: {
          repoWrite: true,
          customScopes: { github: ['workflow', 'repo'] },
        },
      });

      setToken(creds.token);
    })();
  }, [scmAuthApi]);

  return token;
};
