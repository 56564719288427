import React from 'react';
import { Tool } from '@backstage/plugin-home';
import GitHubIcon from '@mui/icons-material/GitHub';
import SecurityIcon from '@mui/icons-material/Security';
import SavingsIcon from '@mui/icons-material/Savings';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { FaAws, FaConfluence } from 'react-icons/fa';
import { DiJira } from 'react-icons/di';
import { IconContext } from 'react-icons';
import CallIcon from '@mui/icons-material/Call';

export const ToolkitTools: Tool[] = [
  {
    url: 'https://rvohealth.okta.com/home/atlassian/0oa2c8odr19C6Wry5697/aln1aqcs055ZRoizW0g8',
    label: 'Jira',
    icon: (
      <IconContext.Provider value={{ size: '2em' }}>
        <DiJira />{' '}
      </IconContext.Provider>
    ),
  },
  {
    url: 'https://rvohealth.okta.com/home/atlassian/0oa2c8odr19C6Wry5697/aln1aqcs056BwpE4X0g8',
    label: 'Confluence',
    icon: (
      <IconContext.Provider value={{ size: '2em' }}>
        <FaConfluence />{' '}
      </IconContext.Provider>
    ),
  },
  {
    url: 'https://github.com/rvohealth',
    label: 'GitHub',
    icon: <GitHubIcon />,
  },
  {
    url: 'https://rvohealth.awsapps.com/start',
    label: 'AWS Identity Center',
    icon: (
      <IconContext.Provider value={{ size: '2em' }}>
        <FaAws />
      </IconContext.Provider>
    ),
  },
  {
    url: 'https://rvohealth.okta.com/home/rvohealth_datadog_1/0oa7gnjk9hUHJjr1t697/aln7gnlnjziKgu1da697',
    label: 'Datadog',
    icon: <MonitorHeartIcon />,
  },
  {
    url: 'https://healthline-media.pagerduty.com/',
    label: 'PagerDuty',
    icon: <CallIcon />,
  },
  {
    url: 'https://rvohealth.okta.com/home/bookmark/0oa98dbw0pH5fH5my697/2557',
    label: 'Wiz',
    icon: <SecurityIcon />,
  },
  {
    url: 'https://rvohealth.okta.com/home/bookmark/0oa3s8tsqgzkeUHRS697/2557',
    label: 'CloudZero',
    icon: <SavingsIcon />,
  },
];
