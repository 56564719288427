import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  analyticsApiRef,
  identityApiRef,
  fetchApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { MultipleAnalyticsApi } from '@backstage/core-app-api';
import { MyOwnClient } from './lib/TechRadar';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { BackstageInsightsApi } from '@spotify/backstage-plugin-analytics-module-insights';
import {
  githubAuthApiRef,
  discoveryApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { GithubAuth } from '@backstage/core-app-api';

export const apis: AnyApiFactory[] = [
  // overwrite the GitHub API so we can request specific OAuth scopes we plan to use
  createApiFactory({
    api: githubAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi }) =>
      GithubAuth.create({
        discoveryApi,
        oauthRequestApi,
        // https://docs.github.com/en/apps/oauth-apps/building-oauth-apps/scopes-for-oauth-apps
        defaultScopes: [
          'read:user',
          'repo',
          'repo:status',
          'repo_deployment',
          'security_events',
        ],
      }),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),

  ScmAuth.createDefaultApiFactory(),

  createApiFactory({
    api: techRadarApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      new MyOwnClient(configApi, identityApi),
  }),

  // https://github.com/backstage/backstage/pull/16642
  createApiFactory({
    api: analyticsApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      fetchApi: fetchApiRef,
      storageApi: storageApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ configApi, identityApi, discoveryApi, fetchApi, storageApi }) =>
      MultipleAnalyticsApi.fromApis([
        BackstageInsightsApi.fromConfig(configApi, {
          discoveryApi,
          fetchApi,
          identityApi,
          storageApi,
        }),
      ]),
  }),
];
