import {
  CatalogProcessorEmit,
  processingResult,
} from '@backstage/plugin-catalog-node';
import {
  parseEntityRef,
  CompoundEntityRef,
  Entity,
} from '@backstage/catalog-model';
import {
  RELATION_HAS_CLOUD_ACCOUNT_ACCESS_TO,
  RELATION_HAS_CLOUD_ACCOUNT_ACCESS_FROM,
} from './relationships';

export function getGitHubDetailsFromEntity(
  entity: Entity,
): { org: string; repo: string } | null {
  if (!entity.metadata.annotations) {
    return null;
  }

  // example: url:https://github.com/healthline/infrastructure-live/tree/master/.backstage/healthy-engineering.yaml
  const url = entity.metadata.annotations['backstage.io/managed-by-location'];

  const data = new RegExp(
    '^url:https://github.com/(?<org>[^/]*)/(?<repo>[^/]*)/',
  ).exec(url);

  if (!data?.groups?.org || !data?.groups?.repo) {
    return null;
  }

  return { org: data.groups.org, repo: data.groups.repo };
}

// helper function to emit relationships for a given entity
// adapted from https://github.com/backstage/backstage/blob/823bf5235e3f4576ef8ee9f424fda517c1559dfa/plugins/catalog-backend/src/modules/core/BuiltinKindsEntityProcessor.ts#L97
export function emitRelationships(
  selfRef: CompoundEntityRef,
  emit: CatalogProcessorEmit,
  target: string | undefined,
  context: { defaultKind?: string; defaultNamespace: string },
): void {
  if (!target) {
    return;
  }
  const targetRef = parseEntityRef(target, context);
  emit(
    processingResult.relation({
      source: selfRef,
      type: RELATION_HAS_CLOUD_ACCOUNT_ACCESS_TO,
      target: {
        kind: targetRef.kind,
        namespace: targetRef.namespace,
        name: targetRef.name,
      },
    }),
  );
  emit(
    processingResult.relation({
      source: {
        kind: targetRef.kind,
        namespace: targetRef.namespace,
        name: targetRef.name,
      },
      type: RELATION_HAS_CLOUD_ACCOUNT_ACCESS_FROM,
      target: selfRef,
    }),
  );
}
