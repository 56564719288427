import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const CloudZeroAccountLink = (props: { accountId: string }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={{ paddingLeft: '12px', color: '#9cc9ff' }}
      href={`https://app.cloudzero.com/explorer?activeCostType=billed_cost&granularity=monthly&partitions=services&dateRange=Last%2012%20Months&accounts=${props.accountId}&showRightFlyout=filters`}
    >
      <FaExternalLinkAlt /> CloudZero
    </a>
  );
};
