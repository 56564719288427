import {
  createPlugin,
  createApiFactory,
  configApiRef,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { entityMetricsApiRef, EntityMetricsClient } from './api/entity-metrics';

import { rootRouteRef } from './routes';

export const entityMetricsPlugin = createPlugin({
  id: 'entity-metrics',
  apis: [
    createApiFactory({
      api: entityMetricsApiRef,
      deps: { fetchApi: fetchApiRef, configApi: configApiRef },
      factory: ({ configApi, fetchApi }) => {
        return new EntityMetricsClient({ configApi, fetchApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const EntityMetricsPage = entityMetricsPlugin.provide(
  createRoutableExtension({
    name: 'EntityMetricsPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
