import React from 'react';
import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import { servicePage } from './ServicePage/ServicePage';
import { websitePage } from './WebsitePage/WebsitePage';
import { defaultEntityPage } from './DefaultPage/DefaultPage';

export const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {servicePage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websitePage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
