import Grid from '@mui/material/Grid2';
import React from 'react';
import { useTeamOwnership } from '../../../../../hooks/useTeamOwnership';
import { ResourceTile } from '../tiles/ResourceTile';
import { BaseSection } from './BaseSection';

// add or remove entity types to display in the Team Ownership section
const displayedEntityTypes = [
  'azure-subscription',
  'aws-account',
  'gcp-project',
  'iac-pipeline',
  'graphql',
  'openapi',
  'asyncapi',
  'library',
  'service',
  'website',
];

export const TeamOwnershipSection: React.FC = () => {
  const { value, loading, error } = useTeamOwnership();

  if (loading) {
    return (
      <BaseSection
        title="Team Ownership"
        subtitle="All the things you own across all your teams."
      >
        Loading...
      </BaseSection>
    );
  }

  if (error) {
    return (
      <BaseSection
        title="Team Ownership"
        subtitle="All the things you own across all your teams."
      >
        Error loading resources
      </BaseSection>
    );
  }

  const resourceTiles = Object.entries(value?.entities ?? {})
    .sort((a, b) => a[0].localeCompare(b[0]))
    .filter(([type]) => displayedEntityTypes.includes(type))
    .map(([type, resources]) => (
      <Grid
        size={{ xs: 12, sm: 12, md: 4, lg: 3, xl: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ResourceTile
          type={type}
          owningGroups={value?.owningGroups ?? []}
          resources={resources}
        />
      </Grid>
    ));

  return (
    <BaseSection
      title="Team Ownership"
      subtitle="All the things you own across all your teams."
    >
      <Grid container spacing={5}>
        {resourceTiles}
      </Grid>
    </BaseSection>
  );
};
