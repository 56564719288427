import React from 'react';

export const RVOHMarkLogo = (props: {
  classes: {
    svg: string;
    path: string;
  };
}) => {
  return (
    <svg
      className={props.classes.svg}
      width="120"
      height="118"
      viewBox="0 0 120 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1175_14588)">
        <path
          className={props.classes.path}
          d="M60 4.12115C90.7741 4.12115 115.804 28.74 115.804 58.9932C115.804 89.2465 90.7741 113.865 60 113.865C29.2259 113.865 4.19647 89.26 4.19647 59.0067C4.19647 28.7535 29.2259 4.12115 60 4.12115ZM60 0C26.8628 0 0 26.4159 0 59.0067C0 91.5976 26.8628 118.013 60 118.013C93.1372 118.013 120 91.5976 120 59.0067C120 26.4159 93.1372 0 60 0Z"
          fill="#1B6DF1"
        />
        <path
          className={props.classes.path}
          d="M59.9182 13.4576C85.4502 13.4576 106.229 33.8877 106.229 58.9929C106.229 84.0981 85.4502 104.528 59.9182 104.528C34.3863 104.528 13.6213 84.1116 13.6213 58.9929C13.6213 33.8742 34.3863 13.4576 59.9182 13.4576ZM59.9182 9.33643C32.0368 9.33643 9.4248 31.5771 9.4248 58.9929C9.4248 86.4087 32.0368 108.649 59.9318 108.649C87.8268 108.649 110.439 86.4087 110.439 58.9929C110.439 31.5771 87.8132 9.33643 59.9182 9.33643Z"
          fill="#1B6DF1"
        />
        <path
          className={props.classes.path}
          d="M59.9183 22.8082C80.2216 22.8082 96.7223 39.0495 96.7223 59.0067C96.7223 78.9639 80.2081 95.2053 59.9183 95.2053C39.6286 95.2053 23.1143 78.9639 23.1143 59.0067C23.1143 39.0495 39.6286 22.8082 59.9183 22.8082ZM59.9183 18.687C37.2655 18.687 18.9043 36.739 18.9043 59.0067C18.9043 81.2744 37.2655 99.3264 59.9183 99.3264C82.5711 99.3264 100.932 81.2744 100.932 59.0067C100.932 36.739 82.5711 18.687 59.9183 18.687Z"
          fill="#1B6DF1"
        />
      </g>
      <defs>
        <clipPath id="clip0_1175_14588">
          <rect width="120" height="118" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
