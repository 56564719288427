import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { TableColumn, Link } from '@backstage/core-components';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import {
  CatalogTable,
  CatalogTableColumnsFunc,
} from '@backstage/plugin-catalog';
import { FaGithub } from 'react-icons/fa';

export const customCatalogColumnsFunc: CatalogTableColumnsFunc =
  entityListContext => {
    const kind = entityListContext.filters.kind?.value;

    switch (kind) {
      case 'component':
        return [
          CatalogTable.columns.createNameColumn(),
          createGitHubLinkColumn(),
          CatalogTable.columns.createOwnerColumn(),
          CatalogTable.columns.createSpecTypeColumn(),
          CatalogTable.columns.createSpecLifecycleColumn(),
          CatalogTable.columns.createTagsColumn(),
        ];
      case 'api':
        return [
          CatalogTable.columns.createNameColumn(),
          CatalogTable.columns.createOwnerColumn(),
          CatalogTable.columns.createSpecTypeColumn(),
          CatalogTable.columns.createSpecLifecycleColumn(),
          CatalogTable.columns.createTagsColumn(),
        ];
      case 'user':
        return [
          CatalogTable.columns.createNameColumn(),
          createGitHubUsernameColumn(),
          createUserJobTitleColumn(),
        ];
      case 'resource':
        return [
          CatalogTable.columns.createNameColumn(),
          CatalogTable.columns.createSpecTypeColumn(),
          CatalogTable.columns.createTagsColumn(),
        ];
      default:
        return CatalogTable.defaultColumnsFunc(entityListContext);
    }
  };

// Creates a column that displays a link to the GitHub repo of the component entity
function createGitHubLinkColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'GitHub Repo',
    render: ({ entity }) => {
      // Our custom processor will add this annotation to to any component that originates from a GitHub repo
      // https://github.com/rvohealth/backstage/blob/main/packages/backend/src/processors/GitHubSlugProcessor.ts
      const gitHubSlug =
        entity.metadata.annotations?.['github.com/project-slug'];

      if (gitHubSlug) {
        return (
          <>
            <Box
              component="span"
              style={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <Box
                component="span"
                style={{
                  marginRight: '4px',
                }}
              >
                <FaGithub />
              </Box>
              <Link to={`https://github.com/${gitHubSlug}`}>{gitHubSlug}</Link>
            </Box>
          </>
        );
      }
      return null;
    },
  };
}

// Creates a column that displays the GitHub username of the user entity
function createGitHubUsernameColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'GitHub Username',
    render: ({ entity }) => {
      // Our custom processor will add this annotation to to any component that originates from a GitHub repo
      // https://github.com/rvohealth/backstage/blob/main/packages/backend/src/processors/GitHubSlugProcessor.ts
      const gitHubUsername =
        entity.metadata.annotations?.['github.com/username'];

      if (gitHubUsername) {
        return <Typography variant="inherit">{gitHubUsername}</Typography>;
      }
      return null;
    },
  };
}

// Creates a column that displays the job title of the user entity
function createUserJobTitleColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Job Title',
    render: ({ entity }) => {
      // Our custom processor will add this annotation to to any component that originates from a GitHub repo
      // https://github.com/rvohealth/backstage/blob/main/packages/backend/src/processors/GitHubSlugProcessor.ts
      const jobTitle = entity.metadata.annotations?.['rvohealth.com/job-title'];

      if (jobTitle) {
        return <Typography variant="inherit">{jobTitle}</Typography>;
      }
      return null;
    },
  };
}
