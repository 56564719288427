import {
  createPlugin,
  createApiFactory,
  configApiRef,
  identityApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import {
  webAccessibilityApiRef,
  WebAccessibilityClient,
} from './api/web-accessibility';

export const webAccessibilityPlugin = createPlugin({
  id: 'web-accessibility',
  apis: [
    createApiFactory({
      api: webAccessibilityApiRef,
      deps: { configApi: configApiRef, identityApi: identityApiRef },
      factory: ({ configApi, identityApi }) => {
        return new WebAccessibilityClient({ configApi, identityApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const WebAccessibilityPage = webAccessibilityPlugin.provide(
  createRoutableExtension({
    name: 'WebAccessibilityPage',
    component: () =>
      import('./components/WebAccessibilityPage').then(
        m => m.WebAccessibilityPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
