import { RELATION_OWNED_BY, ResourceEntity } from '@backstage/catalog-model';
import {
  InfoCard,
  InfoCardVariants,
  StructuredMetadataTable,
} from '@backstage/core-components';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { Avatar, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { IconContext } from 'react-icons';
import { FaAws } from 'react-icons/fa';
import { SiGooglecloud } from 'react-icons/si';
import { VscAzure } from 'react-icons/vsc';

/** @public */
// Create a private class for each cloud account type, it will be used to return the icon and formatted name.
export class CloudAccount {
  public static readonly awsAccount = new CloudAccount(
    'aws-account',
    'AWS Account',
    <FaAws />,
  );
  public static readonly azureSubscription = new CloudAccount(
    'azure-subscription',
    'Azure Subscription',
    <VscAzure />,
  );
  public static readonly gcpProject = new CloudAccount(
    'gcp-project',
    'GCP Project',
    <SiGooglecloud />,
  );
  private constructor(
    public readonly type: string,
    public readonly displayName: string,
    public readonly icon: JSX.Element,
  ) {}

  // Create a static method to return the cloud account type based on the variable.
  static fromType(variable: string): CloudAccount {
    switch (variable) {
      case CloudAccount.awsAccount.type:
        return CloudAccount.awsAccount;
      case CloudAccount.azureSubscription.type:
        return CloudAccount.azureSubscription;
      case CloudAccount.gcpProject.type:
        return CloudAccount.gcpProject;
      default:
        throw new Error(`Unknown cloud account type: ${variable}`);
    }
  }
}

const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <Box ml={1}>{props.title}</Box>
  </Box>
);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  xlarge: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

export const ResourceProfileCard = (props: { variant?: InfoCardVariants }) => {
  const classes = useStyles();
  const { entity: resource } = useEntity<ResourceEntity>();

  const {
    metadata: { description, labels },
    spec: { type },
  } = resource;

  const ownedByRelations = getEntityRelations(resource, RELATION_OWNED_BY);
  const descriptionSplit = description!.split(' | ');
  const labelsAsDictionary = labels as { [key: string]: any };
  // Get the cloud account type from the spec.type
  const cloudAccount = CloudAccount.fromType(type!);

  const metadata = {
    name: descriptionSplit[0],
    owner: ownedByRelations.length > 0 && (
      <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
    ),
    business_unit: descriptionSplit[1],
    accountId: labelsAsDictionary.id,
    status: labelsAsDictionary.account_status,
  };

  return (
    <Grid item xs={12} md={6}>
      <InfoCard title={<CardTitle title="About" />} variant={props.variant}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar className={classes.xlarge}>
                    <IconContext.Provider value={{ size: '2em' }}>
                      {cloudAccount.icon}
                    </IconContext.Provider>
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h3">{metadata.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StructuredMetadataTable metadata={metadata} dense={false} />
            </Grid>
          </Grid>
        </div>
      </InfoCard>
    </Grid>
  );
};
