import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

export const BannerPlugin = createPlugin({
  id: 'banner',
});

export const LoginBannerComponent = BannerPlugin.provide(
  createComponentExtension({
    name: 'NewAnnouncementBanner',
    component: {
      lazy: () =>
        import('./components/LoginBannerComponent').then(
          m => m.LoginBannerComponent,
        ),
    },
  }),
);
