import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TemplateBackstageLogo, homePlugin } from '@backstage/plugin-home';
import { createReactExtension } from '@backstage/core-plugin-api';

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#ffffff',
  },
}));

export const BackstageLogo = () => {
  const { svg, path, container } = useLogoStyles();
  return (
    <div className={container}>
      <TemplateBackstageLogo classes={{ svg, path }} />
    </div>
  );
};

export const HomeBackstageLogo = homePlugin.provide(
  createReactExtension({
    name: 'Backstage Logo',
    component: {
      sync: BackstageLogo,
    },
    data: {
      'home.widget.config': {
        height: { minRows: 2 },
        width: { minColumns: 3 },
      },
    },
  }),
);
