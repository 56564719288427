import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntitySoundcheckContent } from '@spotify/backstage-plugin-soundcheck';
import {
  EntitySecurityInsightsContent,
  EntityGithubDependabotContent,
  isSecurityInsightsAvailable,
} from '@internal/github-plugin-security-insights';
import { EntityDXDashboardContent } from '@get-dx/backstage-plugin';
import {
  overviewContent,
  techdocsContent,
  ghInsightsInternalErrMsg,
  ghInsightsNotFoundErrMsg,
} from '../../shared';

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/code-scanning"
      title="Code Scanning"
      // shows the tab on entities with the correct annotations already set
      if={isSecurityInsightsAvailable}
    >
      <EntitySecurityInsightsContent
        notFoundErrMsg={ghInsightsNotFoundErrMsg}
        internalErrMsg={ghInsightsInternalErrMsg}
      />
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/dependabot"
      title="Dependabot"
      // shows the tab on entities with the correct annotations already set
      if={isSecurityInsightsAvailable}
    >
      <EntityGithubDependabotContent
        notFoundErrMsg={ghInsightsNotFoundErrMsg}
        internalErrMsg={ghInsightsInternalErrMsg}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/soundcheck" title="Soundcheck">
      <EntitySoundcheckContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dx" title="DX">
      <EntityDXDashboardContent />
    </EntityLayout.Route>
  </EntityLayout>
);
