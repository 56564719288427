export interface CloudAccount {
  id: string;
  name: string;
  accountStatus: 'open' | 'closed';
  type: 'aws' | 'gcp' | 'azure';
  owner: string;
  businessUnit: string;
  updatedOn: string;
  createdOn: string;
}

export interface CloudAccountCostData {
  costData: CloudAccountCost[];
}

export interface CloudAccountCost {
  accountName: string;
  accountType: 'aws' | 'gcp' | 'azure';
  accountID: string;
  costData: CostRecord[];
}

export type CostRecord = { year: number; month: number; cost: number };

export const isCostRecord = (data: any): data is CostRecord => {
  if (!data || typeof data !== 'object') {
    return false;
  }

  return (
    typeof data.year === 'number' &&
    typeof data.month === 'number' &&
    typeof data.cost === 'number'
  );
};

export const isCostData = (data: any): data is CloudAccountCostData[] => {
  if (!data || !Array.isArray(data)) {
    return false;
  }

  for (const item of data) {
    if (
      !item ||
      typeof item !== 'object' ||
      typeof item.accountName !== 'string' ||
      typeof item.accountType !== 'string' ||
      typeof item.accountID !== 'string' ||
      !Array.isArray(item.costData) ||
      !item.costData.every(isCostRecord)
    ) {
      return false;
    }
  }

  return true;
};

export const isCloudAccountCostData = (
  data: any,
): data is CloudAccountCostData => {
  if (!data.costData || !Array.isArray(data.costData)) {
    return false;
  }

  return isCostData(data.costData);
};
