import React from 'react';
import {
  EntitySwitch,
  isKind,
  isResourceType,
} from '@backstage/plugin-catalog';
import { domainPage } from './DomainPage/DomainPage';
import { resourcePage } from './ResourcePage/ResourcePage';
import { userPage } from './UserPage/UserPage';
import { systemPage } from './SystemPage/SystemPage';
import { groupPage } from './GroupPage/GroupPage';
import { componentPage } from './ComponentPage/ComponentPage';
import { apiPage } from './ApiPage/ApiPage';
import { defaultEntityPage } from './ComponentPage/DefaultPage/DefaultPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case
      if={
        isKind('resource') &&
        isResourceType(['aws-account', 'azure-subscription', 'gcp-project'])
      }
      children={resourcePage}
    />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
