import { Header } from '@backstage/core-components';
import React from 'react';
import { useUserProfile } from '../../../../../hooks/useUserProfile';

export const HomePageHeader = () => {
  const profile = useUserProfile();

  return (
    <Header title={profile?.displayName} subtitle="Thank you for being here!" />
  );
};
