import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import {
  EntityAboutCard,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import { EntityGithubDeploymentsCard } from '@backstage-community/plugin-github-deployments';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EntitySoundcheckCard } from '@spotify/backstage-plugin-soundcheck';
import { EmptyState, CodeSnippet } from '@backstage/core-components';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ReportIssue,
  LightBox,
  TextSize,
  ExpandableNavigation,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import {
  SecurityInsightsWidget,
  DependabotAlertsWidget,
  isSecurityInsightsAvailable,
} from '@internal/github-plugin-security-insights';
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@pagerduty/backstage-plugin';
import { EntityWarningContent } from './EntityWarningContent';
import { EntityLink } from '@backstage/catalog-model';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import LanguageIcon from '@material-ui/icons/Language';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { Link } from '@backstage/core-components';

const WebLink = ({
  href,
  Icon,
  text,
}: {
  href: string;
  text?: string;
  Icon?: IconComponent;
}) => (
  <ListItem key={href}>
    <ListItemIcon>{Icon ? <Icon /> : <LanguageIcon />}</ListItemIcon>
    <ListItemText>
      <Link to={href}>{text}</Link>
    </ListItemText>
  </ListItem>
);

export const LinksGroup = ({ links }: { links?: EntityLink[] }) => {
  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  if (links === undefined) {
    return null;
  }

  return (
    <>
      <Divider />
      {links.map(link => {
        return (
          <WebLink
            key={link.url}
            href={link.url}
            text={link.title}
            Icon={iconResolver(link.icon)}
          />
        );
      })}
    </>
  );
};

export const ghInsightsNotFoundErrMsg =
  'The process has not been setup in GitHub or you do not have sufficient permissions in GitHub. Please contact the repo maintainer or reach out in #backstage-discussion.';
export const ghInsightsInternalErrMsg =
  'There was an error fetching the data. Please reach out in #backstage-discussion';

export const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
      <LightBox />
      <TextSize />
      <ExpandableNavigation />
      <Mermaid
        config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
      />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

export const cicdContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const DEPLOYMENTS_EMPTY_YAML = `metadata:
  name: my-app
  annotations:
    github.com/project-slug: rvohealth/my-app`;

export const deploymentsContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={12} md={12}>
          <EntityGithubDeploymentsCard />
        </Grid>
      </Grid>
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No Deployments available for this entity"
        missing="info"
        description={
          <>
            <Typography>
              To enable deployments, add the following annotation to your
              entity.
            </Typography>
            <CodeSnippet
              text={DEPLOYMENTS_EMPTY_YAML}
              language="yaml"
              showLineNumbers
              highlightedNumbers={[4]}
              customStyle={{ background: 'inherit', fontSize: '115%' }}
            />
          </>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

export const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <EntityWarningContent />
    <Grid item md={4}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={4} xs={12}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={12} xs={12}>
          <EntitySoundcheckCard />
        </Grid>
      </Grid>
    </Grid>
    <Grid item md={4} xs={12}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isSecurityInsightsAvailable}>
        <Grid item md={4} xs={12}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item md={12} xs={12}>
              <SecurityInsightsWidget
                notFoundErrMsg={ghInsightsNotFoundErrMsg}
                internalErrMsg={ghInsightsInternalErrMsg}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <DependabotAlertsWidget
                notFoundErrMsg={ghInsightsNotFoundErrMsg}
                internalErrMsg={ghInsightsInternalErrMsg}
              />
            </Grid>
          </Grid>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isPagerDutyAvailable}>
        <Grid item md={4} xs={12}>
          <EntityPagerDutyCard readOnly />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
