import { Entity } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { useUserProfile } from './useUserProfile';
import { getGroupMembership } from '../util/getGroupMembership';

export const useTeamOwnership = () => {
  const profile = useUserProfile();
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const { value, loading, error } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();

    if (!token || !profile?.backstageIdentity?.userEntityRef) {
      return {
        owningGroups: [],
        entities: {},
      };
    }

    const groups = await getGroupMembership(
      catalogApi,
      profile.backstageIdentity.userEntityRef,
      token,
    );

    const entities = await catalogApi.getEntities(
      {
        filter: [
          {
            'relations.ownedBy': groups,
          },
        ],
      },
      {
        token,
      },
    );

    // filter costs down just to those that the user or their team owns
    const entityMap = entities.items.reduce(
      (acc: Record<string, Entity[]>, resource: Entity) => {
        const type = resource.spec!.type as string;
        if (type in acc) {
          acc[type].push(resource);
        } else {
          acc[type] = [resource];
        }
        return acc;
      },
      {},
    );

    return {
      owningGroups: groups,
      entities: entityMap,
    };
  }, [profile?.backstageIdentity?.userEntityRef]);

  return { value, loading, error };
};
