import { Content, Page } from '@backstage/core-components';
import {
  CustomHomepageGrid,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { LoginBannerComponent } from '@internal/plugin-banner';
import { Divider, Grid, Typography } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';
import React from 'react';
import { HomeTeamCard } from '../TeamCard/TeamCard';
import { HomePageHeader } from './header/HomePageHeader';
import { TeamOwnershipSection } from './sections/TeamOwnershipSection';
import { CloudCostTile } from './tiles/CloudCostTile';

export const NewHomePage = () => {
  const homepageGridConfig = [
    {
      component: <HomePageStarredEntities />,
      x: 0,
      y: 0,
      width: 6,
      height: 5,
      movable: true,
      resizable: true,
      deletable: true,
    },
    {
      component: <HomeTeamCard />,
      x: 6,
      y: 0,
      width: 6,
      height: 5,
      movable: true,
      resizable: true,
      deletable: true,
    },
  ];

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <HomePageHeader />
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <LoginBannerComponent />
            <NewAnnouncementBanner />
            <Stack spacing="12px" width="100%" padding="24px">
              <>
                <Typography variant="h4">Team Metrics</Typography>
                <Typography variant="subtitle1">
                  The metrics are an aggregate across all your teams.
                </Typography>
                <Stack
                  direction="row"
                  style={{
                    width: '100%',
                    marginTop: '12px',
                  }}
                  spacing="6px"
                  justifyContent="space-around"
                >
                  <CloudCostTile />
                </Stack>
              </>
              <Divider
                style={{
                  marginTop: '24px',
                  marginBottom: '12px',
                }}
              />
              <TeamOwnershipSection />
              <Divider
                style={{
                  marginTop: '24px',
                  marginBottom: '12px',
                }}
              />
              <CustomHomepageGrid config={homepageGridConfig}>
                <HomePageStarredEntities />
                <HomeTeamCard />
              </CustomHomepageGrid>
            </Stack>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
