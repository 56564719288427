import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const rvohBrightBlue = '#1B6DF1';
const rvohWhite = '#FFFFFF';
const rvohGrey = '#444444';

export const rvohDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      background: {
        paper: rvohGrey,
        default: '#333333',
      },
      // https://github.com/backstage/backstage/blob/master/packages/theme/src/base/palettes.ts
      navigation: {
        background: rvohBrightBlue,
        indicator: '#9BF0E1',
        color: rvohWhite,
        selectedColor: '#FFF',
        navItem: {
          hoverBackground: '#404040',
        },
        submenu: {
          background: '#404040',
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Arial',
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Arial',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    documentation: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    tool: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    service: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    website: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    library: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    other: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    app: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
    apis: genPageTheme({
      colors: [rvohBrightBlue],
      shape: shapes.round,
    }),
  },
});
