import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'entity-metrics',
});

export const unregisteredReposRouteRef = createSubRouteRef({
  id: 'entity-metrics/unregistered',
  path: '/unregistered/*',
  parent: rootRouteRef,
});
