import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { Grid, Input, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  TechRadarComponent,
  type TechRadarComponentProps,
} from '@backstage-community/plugin-tech-radar';

const useStyles = makeStyles(() => ({
  overflowXScroll: {
    overflowX: 'scroll',
  },
}));

/**
 * Properties for {@link TechRadarPage}
 *
 * @public
 */
export interface TechRadarPageProps extends TechRadarComponentProps {
  /**
   * Title
   */
  title?: string;
  /**
   * Subtitle
   */
  subtitle?: string;
  /**
   * Page Title
   */
  pageTitle?: string;
}

/**
 * Main Page of Tech Radar
 *
 * @public
 */
export function TechRadarPage(props: TechRadarPageProps) {
  const {
    title = 'Tech Radar',
    subtitle = 'Recommended technologies for your projects',
    pageTitle = 'Company Radar',
    ...componentProps
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');

  const supportItems = [
    {
      title: 'Documentation',
      links: [
        {
          url: 'https://backstage.rvohealth.dev/catalog/default/component/tech-radar/docs',
          title: 'tech-radar',
        },
      ],
    },
    {
      title: 'GitHub Repo',
      links: [
        {
          url: 'https://github.com/rvohealth/tech-radar',
          title: 'rvohealth/tech-radar',
        },
      ],
    },
  ];
  return (
    <Page themeId="tool">
      <Header title={title} subtitle={subtitle} />
      <Content className={classes.overflowXScroll}>
        <ContentHeader title={pageTitle}>
          <Input
            id="tech-radar-filter"
            type="search"
            placeholder="Filter"
            onChange={e => setSearchText(e.target.value)}
          />
          <SupportButton title="Have questions?" items={supportItems} />
        </ContentHeader>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} sm={6} md={4}>
            <TechRadarComponent searchText={searchText} {...componentProps} />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
}
