import {
  createPlugin,
  createComponentExtension,
  identityApiRef,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { teamsApiRef, TeamsClient } from './api/teams';

export const teamsPlugin = createPlugin({
  id: 'teams',
  apis: [
    createApiFactory({
      api: teamsApiRef,
      deps: { identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ identityApi, configApi }) => {
        return new TeamsClient({ identityApi, configApi });
      },
    }),
  ],
});

export const EntityTeamConfigTab = teamsPlugin.provide(
  createComponentExtension({
    name: 'EntityTeamConfigTab',
    component: {
      lazy: () =>
        import('./components/EntityTeamConfigTab').then(
          m => m.EntityTeamConfigTab,
        ),
    },
  }),
);
